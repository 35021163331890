<template>
  <section class="hero is-fullheight-with-navbar">
    <div class="hero-body">
      <h1 class="headline has-text-black">Animation</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AnimationFun',
  title: 'Carly Bergthold - Animation'
}
</script>

<style scoped>
.headline {
  color: white;
  margin-top: 8rem;
  font-size: 4rem;
  font-weight: 800;
  text-shadow: 1px 1px 0px var(--teal),
	4px 2px 0px var(--teal),
	5px 3px 0px var(--teal),
	4px 4px 0px var(--teal);
}
</style>